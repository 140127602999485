import { useOfferingStore } from '~/stores/offering'
import { useCourseStore } from '~/stores/course'
import { useCourseAreaStore } from '~/stores/courseArea'

import useHelpers from '~/composables/useHelpers'

import Offering = models.server.api.curriculum.offering.Offering

import capePageMap from '~/data/cape-page-map.json'

export default function () {
  const regexConditions = [
    '/(course|offering)/-/././(.*?)/', // course/offering pages ([1], 2)
    '/(courses)/(.*?)($|[?/])', // cape pages ([3], 4, 5)
    '/(search)[?](.*)', // search ([6], 7)
    '/(course|offering)/(.*?)($|[?/])' // course/offering with id pages ([8], 9)
  ]

  const regex = new RegExp(regexConditions.join('|'), 'i')

  async function getRedirectUrl(from?: string | null) {
    if (!from) return undefined

    const match = removeTrailingSlash(from)?.match(regex)
    if (!match) return undefined

    let redirectUrl: string | undefined = undefined
    let offering: Offering | undefined = undefined

    const { getProductUrl } = useHelpers()
    switch (match[1] || match[3] || match[6] || match[8]) {
      case 'offering':
        const offeringId = match[2] || match[9]
        if (offeringId) {
          const offeringStore = useOfferingStore()
          offering = await offeringStore.fetchOne(offeringId)
        }

      case 'course':
        const courseId = offering ? offering.courseId : match[2] || match[9]
        if (courseId) {
          const courseStore = useCourseStore()
          const course = await courseStore.fetchOne(courseId)
          redirectUrl = getProductUrl({ course, offering })
        }
        break

      case 'courses':
        const key = match[4] as keyof typeof capePageMap
        const courseAreaSlug = capePageMap[key]?.slug
        if (courseAreaSlug) {
          const courseAreaStore = useCourseAreaStore()
          const courseArea = await courseAreaStore.fetchBySlug(courseAreaSlug)
          redirectUrl = getProductUrl({ courseArea })
        }
        break

      case 'search': {
        const queryString = match[7]
          .split('&')
          .reduce((obj: Record<string, string>, s) => {
            const q = s.split('=')
            obj[q[0]] = q[1]
            return obj
          }, {})
        redirectUrl = `/course-search?keyword=${queryString.q || ''}`
      }
    }

    if (!redirectUrl) throw new Error('status code 404')

    return redirectUrl
  }

  function removeTrailingSlash(str?: string) {
    return str && str.replace(/\/+$/, '')
  }

  return { getRedirectUrl }
}
